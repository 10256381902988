import React from "react";
import FilterGeneral from "./filter-general";
import { graphql } from "gatsby";

const PlaceFilterIndex = (props) => <FilterGeneral {...props} />
export default PlaceFilterIndex;

export const pageQuery = graphql`
  query BlogPostByPlace($place: String!){
    site {
      siteMetadata {
        title
        social {
          facebook,
          instagram
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: {place: { eq: $place}}}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY")
            title
            moods
            place
            cover {
              childImageSharp {
                ... on ImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`